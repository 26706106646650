/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import { withRouter, Redirect, Switch, Route, NavLink, Link } from 'react-router-dom';
import { withCookies, Cookies } from 'react-cookie';
import { connect } from 'react-redux';
import { instanceOf } from 'prop-types';


import { Button, Image, Dropdown } from 'semantic-ui-react';

//import Rotator from './rotator';
import { List } from 'immutable';
import { HashLink } from 'react-router-hash-link';

import { getTokenPrice } from './helpers/sortPrices';
import LoginAction from './login.component';

import * as walletActions from '../wallet/walletActions';
import * as priceActions from '../prices/pricesActions';

import Header from './header';
import Footer from './footer';
import Eosio from '../tokens/eosio/eosio';
import TelosReward from '../tokens/rex/simple/eosioRex';

/**********************
 Pages
 *********************/
//History
import Account from './account';

//Request
import Request from './request';

//Error Page
import Error from './error/error';

class MainBody extends Component {
  componentWillUnmount() {
  }

  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  constructor(props) {
    super(props);
    // Don't call this.setState() here!
    const { cookies } = props;
    this.state = { visible: false, value: 0, networks: [{ disabled: false, text: "Telos", url: "https://staker.one/", value: 0, image: { avatar: true, src: process.env.PUBLIC_URL + "/assets/logos/tlos-logo.svg" }}, { disabled: false, text: "EOS", url: "https://eos.staker.one", value: 1, image: { avatar: true, src: "https://atomlaunch.com/wp-content/uploads/2020/01/eos.png" }}, { disabled: false, text: "WAX", url: "https://wax.staker.one", value: 2, image: { avatar: true, src: "https://atomlaunch.com/wp-content/uploads/2020/01/wax.png"}}], theme: cookies.get('theme') };
    this.themeHandler = this.themeHandler.bind(this);
  }

  componentDidMount() {
    const { getTokenPrices, acctName, getMessages, restApi } = this.props;
    getMessages();
    restApi();
    //console.log(cookies.get('theme'));

    if (acctName) {
      //getAllBalances(acctName);
    }

    if (!acctName) {
      //signInToWallet(acctName);
      getTokenPrices();
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    //console.log(this.state.networks)
    if (this.state.visible !== nextState.visible) {
      return true;
    }

    if (this.state.theme !== nextState.theme) {
      document.documentElement.setAttribute("data-theme", nextState.theme);
    }

    return true;
  }

  themeHandler(themeStyle) {
    const { cookies } = this.props;

    cookies.set('theme', themeStyle, {path: "/", maxAge: 1000000});
    this.setState({ theme: themeStyle });
  }

  handleChange = (e, { value }) => value !== this.state.value ? window.location = this.state.networks[value].url : null;

  toggleMenu = () => this.setState(prevState => ({ visible: !prevState.visible }));

  render() {
    const { acctName, tokenPrices } = this.props;
    const { visible, value, networks, theme } = this.state;
    const menuIconSize = { width: '4rem' };
    const whitelist = ['/rex'];

    if (!acctName && whitelist.indexOf(this.props.history.location.pathname) !== -1) {
      return <Redirect to="/" />;
    }

    if (this.props.history.location.pathname === "/token" || this.props.history.location.pathname === "/") {
      return <Redirect to="/token/telos" />;
    }

    const mainMenuItems = [
      { text: 'Staking (REX)', src: '/rewards' },
      //{ text: 'Manage Rex', src: '/rex' },
      //{ text: 'Portfolio', src: '/portfolio' },
      //{ text: 'Request Listing', src: '/request' },
      //{ text: 'About Staker', src: '/about' },
    ];

    const mainMenu = (items, close) => items.map((item) => {
      return (
        <li className="st-top-menu__item" key={item.text} onClick={close}>
          <NavLink to={item.src} activeClassName="active">
            <span>{item.text}</span>
          </NavLink>
        </li>
      );
    });

    return (
      <div className={`st-main-wrapper ${visible ? 'st-main-wrapper--side-active' : ''}`}>
        <div className="st-header" id="header">
          <div className="st-header__left">
            <Image as={Link} to="/" src={process.env.PUBLIC_URL + "/assets/images/staker_logo_white.svg"} />

            {/*
            <div className="st-network-wrap">
              <Image src={networks && networks[value].image.src} style={{ height: '25px', borderRadius: '100px' }} />
              <Dropdown
                pointing="top left"
                header="Select A Network"
                className="icon"
                inline
                multiple
                options={networks}
                value={value}
                onChange={this.handleChange}
              />
            </div>
            */}

            <ul className="st-top-menu">
              <li className="st-top-menu__item" key="View Account">
                <NavLink to="/" activeClassName={window.location.href.indexOf("token") > -1 ? 'active' : ''}>
                  <span>Home</span>
                </NavLink>
              </li>
              {mainMenu(mainMenuItems, null)}
            </ul>
          </div>
          <div className="st-header__right">
            <LoginAction themeHandler={this.themeHandler} theme={theme} />
            <div
              className={`st-burger ${visible ? 'st-burger--active' : ''}`}
              onClick={this.toggleMenu}
              onKeyDown={this.toggleMenu}
            >
              <div className="st-burger__item" />
              <div className="st-burger__item" />
              <div className="st-burger__item" />
            </div>
          </div>
        </div>

        <div className="st-main-body-wrapper">
          <div
            visible={this.state.visible}
            className="st-main-sidebar"
          >
            <Button className="st-main-sidebar__toggle" onClick={this.toggleMenu}>
              <Image src={process.env.PUBLIC_URL + "/assets/images/chevron-purple-right.svg"} />
            </Button>
            <div className="mobile-menu-bar">
              <Dropdown
                pointing="top left"
                header="Select A Network"
                className="icon"
                placeholder={<Image src={networks && networks[value].image.src} style={{ height: '25px', borderRadius: '100px' }} />}
                inline
                multiple
                options={networks}
                value={value}
                onChange={this.handleChange}
              />
              <button
                basic
                circular
                icon
                className="st-button st-button--theme-switcher"
                type="button"
                onClick={() => {
                  this.themeHandler(theme === 'dark' ? 'light' : 'dark')
                }}
              >
                <Image className="theme-switcher" src={theme === 'dark' ? '../assets/theme/dark-on.svg' : '../assets/theme/dark-off.svg'} width="22px" />
              </button>
            </div>

            <ul className="st-side-nav">
              <li className="st-side-nav__item">
                <HashLink to="/#ram" activeClassName="active" onClick={visible && this.toggleMenu} scroll={el => el.scrollIntoView({ behavior: 'smooth', block: 'start', inline: "nearest" })}>
                  <div className="st-side-nav__icon">
                    <Image src={process.env.PUBLIC_URL + "/assets/icons/ram-icon.svg"} centered circular style={menuIconSize} />
                  </div>
                  <span> Manage RAM  </span>
                </HashLink>
              </li>
              <li className="st-side-nav__item">
                <HashLink to="/#stake" activeClassName="active" onClick={visible && this.toggleMenu} scroll={el => el.scrollIntoView({ behavior: 'smooth', block: 'start', inline: "nearest" })}>
                  <div className="st-side-nav__icon">
                    <Image src={process.env.PUBLIC_URL + "/assets/icons/net-icon.svg"} centered circular style={menuIconSize} />
                  </div>
                  <span> Stake CPU/NET </span>
                </HashLink>
              </li>
              {/*
              <li className="st-side-nav__item">
                <HashLink to="/#unstake" exact activeClassName="active" onClick={visible && this.toggleMenu} scroll={el => el.scrollIntoView({ behavior: 'smooth', block: 'start', inline: "nearest" })}>
                  <div className="st-side-nav__icon">
                    <Image src={process.env.PUBLIC_URL + "/assets/icons/net-icon.svg"} centered circular style={menuIconSize} />
                  </div>
                  <span> Unstake CPU/NET </span>
                </HashLink>
              </li>
              */}
              <li className="st-side-nav__item">
                <a href={process.env.PUBLIC_URL + '/rewards'} exact activeClassName="active" onClick={visible && this.toggleMenu}>
                  <div className="st-side-nav__icon">
                    <Image src={process.env.PUBLIC_URL + "/assets/icons/rex-icon.svg"} centered circular style={menuIconSize} />
                  </div>
                  <span> Start earning through REX </span>
                </a>
              </li>
            </ul>
          </div>
          <div className="st-main-body">
            <Header />
            {/*<Rotator />*/}
            <Switch>
              <Route exact path="/token/telos" render={() => <Eosio content={Eosio} TokenPrices={getTokenPrice(tokenPrices, "telos")} />} />
              <Route exact path="/rewards" render={() => <TelosReward content={TelosReward} TokenPrices={getTokenPrice(tokenPrices, "telos")} RexPrice={getTokenPrice(tokenPrices, "telos-rex")} />} />
              <Route exact path="/portfolio" component={Account} />
              <Route exact path="/request" component={Request} />
              <Route exact component={Error} />
            </Switch>
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}

export default withCookies(withRouter(connect(
  (state) => {
    const acctName = state.data.getIn(['identity', 'account_name']);
    const tokenPrices = state.prices.getIn(['prices', 'data'], List()).toJS();
    return {
      acctName,
      tokenPrices,
    };
  },
  dispatch => ({
    getMessages: () => {
      dispatch(walletActions.getMessagesRoutine());
    },
    getTokenPrices: (args) => {
      dispatch(priceActions.getTokenPricesRoutine(args));
    },
    signInToWallet: (args) => {
      dispatch(walletActions.signInToWalletRoutine(args));
    },
    signOutOfWallet: (args) => {
      dispatch(walletActions.signOutOfWalletRoutine(args));
    },
    getAllBalances: (args) => {
      dispatch(walletActions.getAccountBalancesRoutine(args));
    },
    restApi: id => dispatch(walletActions.restApiRoutine({ id }))
  })
)(MainBody)));
