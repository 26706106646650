import {put, takeLatest} from 'redux-saga/effects';
import { withUAL } from 'ual-reactjs-renderer'
import * as walletActions from './walletActions';
import * as walletClientApi from './walletClientApi';
import { getAllData } from '../accountData/fetchClientApi';
import { getRestApi } from '../api/rest_api';
import {showProcessAlert, showSuccessAlert, showErrorAlert} from '../alerts/showAlert';

export const walletWatches = [
  connectToWalletWatch,
  signInToWalletWatch,
  signOutOfWalletWatch,
  restApiWatch,
  claimAirHodlWatch,
  transferEosWatch,
  stakeEosWatch,
  unStakeEosWatch,
  dataLoadedWatch,
  connectedWatch,
  buyRexWatch,
  unstakeToRexWatch,
  sellRexWatch,
  borrowEosWatch,
  voteProxyWatch,
  tokenSwapWatch
];

export function* connectToWalletWatch() {
  yield takeLatest(walletActions.connectToWalletRoutine.TRIGGER, connectToWallet);
}

export function* signInToWalletWatch() {
  yield takeLatest(walletActions.signInToWalletRoutine.TRIGGER, signInToWallet);
}

export function* signOutOfWalletWatch() {
  yield takeLatest(walletActions.signOutOfWalletRoutine.TRIGGER, signOutOfWallet);
}

export function* restApiWatch() {
  yield takeLatest(walletActions.restApiRoutine.TRIGGER, fetchRestApi);
}

export function* dataLoadedWatch() {
  yield takeLatest(walletActions.dataLoadedRoutine.TRIGGER, dataLoaded);
}

export function* connectedWatch() {
  yield takeLatest(walletActions.connectedRoutine.TRIGGER, accountConnected);
}

export function* claimAirHodlWatch() {
  yield takeLatest(walletActions.claimAirHodlRoutine.TRIGGER, claimAirHodl);
}

export function* transferEosWatch() {
  yield takeLatest(walletActions.transferEosRoutine.TRIGGER, transferEos);
}

export function* stakeEosWatch() {
  yield takeLatest(walletActions.stakeEosRoutine.TRIGGER, stakeEos);
}

export function* unStakeEosWatch() {
  yield takeLatest(walletActions.unStakeEosRoutine.TRIGGER, unStakeEos);
}

export function* buyRexWatch() {
  yield takeLatest(walletActions.lendRexRoutine.TRIGGER, lendRex);
}

export function* unstakeToRexWatch() {
  yield takeLatest(walletActions.unstakeToRexRoutine.TRIGGER, unstakeToRex);
}

export function* sellRexWatch() {
  yield takeLatest(walletActions.sellRexRoutine.TRIGGER, sellRex);
}

export function* borrowEosWatch() {
  yield takeLatest(walletActions.borrowRexRoutine.TRIGGER, borrowEos);
}

export function* voteProxyWatch() {
  yield takeLatest(walletActions.voteProxyRoutine.TRIGGER, voteProxy);
}

export function* tokenSwapWatch() {
  yield takeLatest(walletActions.tokenSwapRoutine.TRIGGER, swapToken);
}


function* dataLoaded() {
  yield put(walletActions.dataLoadedRoutine.success());
}

function* accountConnected() {
  yield put(walletActions.connectedRoutine.success());
}

function* connectToWallet(payload) {
  //yield walletClientApi.wallet(payload);
  const WalletConnected = yield walletClientApi.connectToWallet(payload);
  if(WalletConnected) {
    const Wallet = yield walletClientApi.getWallet();
    //const identity = yield walletClientApi.getWalletIdentity();
    if(Wallet) {
      yield put(walletActions.connectToWalletRoutine.success());
    }
  } else {
    yield put(walletActions.connectToWalletRoutine.failure());
  }
}

function* signInToWallet({payload}) {
  const serverResponse = yield getAllData({account_name: payload.accountName});
  if (serverResponse) {
    yield dataLoaded();
    yield put(walletActions.signInToWalletRoutine.success(serverResponse));
    yield put(walletActions.getAccountBalancesRoutine.success(serverResponse));
  } else {
    yield put(walletActions.getAccountBalancesRoutine.failure(serverResponse));
  }
}

function* signOutOfWallet() {
  const result = yield walletClientApi.forgetIdentity();
  if(result===true) {
    yield put(walletActions.signOutOfWalletRoutine.success(result));
  } else {
    yield put(walletActions.signOutOfWalletRoutine.failure());
  }
}

function* fetchRestApi() {
  const result = yield getRestApi();
  if(result) {
    yield put(walletActions.restApiRoutine.success(result));
  } else {
    yield put(walletActions.restApiRoutine.failure());
  }
}

function* transferEos({payload}) {
  showProcessAlert(); // Message
  const serverResponse = yield walletClientApi.broadcastTransaction({
    contract: 'eosio.token',
    method: 'transfer',
    data: payload,
  });
  if(serverResponse.processed) {
    //console.log(serverResponse)
    showSuccessAlert('Transaction was Successful.', serverResponse.transaction_id);
    yield put(walletActions.transferEosRoutine.success(serverResponse.processed));
    const getBalances = yield getAllData({account_name: payload.from});
    if(getBalances) {
      yield put(walletActions.getAccountBalancesRoutine.success(getBalances));
    }
  } else {
    (serverResponse.message ? showErrorAlert(serverResponse.message) : showErrorAlert(serverResponse));
    yield put(walletActions.transferEosRoutine.failure(serverResponse));
  }
}

export const stakeEos = ({payload}) => { withUAL(stakeEosAction(payload)) }
function* stakeEosAction({payload}) {
  showProcessAlert(); // Message
  //console.log(this.props.ual)
  if (!parseFloat(payload.id.stake_cpu_quantity) && !parseFloat(payload.id.stake_net_quantity)) {
    showErrorAlert('You must enter a value to stake in CPU or NET'); // Message
  } else {
    const serverResponse = yield walletClientApi.broadcastTransaction({
      contract: 'eosio',
      method: 'delegatebw',
      data: payload.id,
    });
    if(serverResponse.processed) {
      showSuccessAlert('Transaction was Successful.', serverResponse.transaction_id);
      const getBalances = yield getAllData({account_name: payload.id.from});
      if(getBalances) {
        yield put(walletActions.getAccountBalancesRoutine.success(getBalances));
        yield put(walletActions.stakeEosRoutine.success(serverResponse.processed));
      }
    } else {
      (serverResponse.message ? showErrorAlert(serverResponse.message) : showErrorAlert(serverResponse));
      yield put(walletActions.stakeEosRoutine.failure(serverResponse));
    }
  }
}

function* unStakeEos({payload}) {
  showProcessAlert('Transaction in progress, please stand by...'); // Message
  //console.log(payload.id);
  if (!parseFloat(payload.id.unstake_cpu_quantity) && !parseFloat(payload.id.unstake_net_quantity)) {
    showErrorAlert('You must enter a value to unstake in CPU or NET'); // Message
  } else {
    const serverResponse = yield walletClientApi.broadcastTransaction({
      contract: 'eosio',
      method: 'undelegatebw',
      data: payload.id,
    });
    if(serverResponse.processed) {
      showSuccessAlert('Transaction was Successful.', serverResponse.transaction_id);
      yield put(walletActions.stakeEosRoutine.success(serverResponse.processed));
    } else {
      (serverResponse.message ? showErrorAlert(serverResponse.message) : showErrorAlert(serverResponse));
      yield put(walletActions.stakeEosRoutine.failure(serverResponse));
    }
  }
}


function* claimAirHodl({payload}) {
  showProcessAlert('Transaction in progress, please stand by...'); // Message
  //console.log(payload);
  const serverResponse = yield walletClientApi.broadcastTransaction({
    contract: 'dappairhodl1',
    method: 'grab',
    data: payload,
  });
  if(serverResponse.processed) {
    showSuccessAlert('Transaction was Successful.', serverResponse.transaction_id);
    yield put(walletActions.claimAirHodlRoutine.success(serverResponse.processed));
  } else {
    (serverResponse.message ? showErrorAlert(serverResponse.message) : showErrorAlert(serverResponse));
    yield put(walletActions.claimAirHodlRoutine.failure(serverResponse));
  }
}

function* lendRex({payload}) {
  showProcessAlert('Transaction in progress, please stand by...'); // Message
  //console.log(payload);
  const serverResponse = yield walletClientApi.broadcastTransaction({
    contract: 'eosio',
    method: 'buyrex',
    data: payload.id.data,
  });
  if(serverResponse.processed) {
    showSuccessAlert('Transaction was Successful.', serverResponse.transaction_id);
    yield put(walletActions.lendRexRoutine.success(serverResponse.processed));
    const getBalances = yield getAllData({account_name: payload.id.acctName});
    if(getBalances) {
      yield put(walletActions.getAccountBalancesRoutine.success(getBalances));
    }
  } else {
    (serverResponse.message ? showErrorAlert(serverResponse.message) : showErrorAlert(serverResponse));
    yield put(walletActions.lendRexRoutine.failure(serverResponse));
  }
}

function* unstakeToRex({payload}) {
  showProcessAlert('Transaction in progress, please stand by...'); // Message
  //console.log(payload);
  const serverResponse = yield walletClientApi.broadcastTransaction({
    contract: 'eosio',
    method: 'unstaketorex',
    data: payload.id.data,
  });
  if(serverResponse.processed) {
    showSuccessAlert('Transaction was Successful.', serverResponse.transaction_id);
    yield put(walletActions.unstakeToRexRoutine.success(serverResponse.processed));
    const getBalances = yield getAllData({account_name: payload.id.acctName});
    if(getBalances) {
      yield put(walletActions.getAccountBalancesRoutine.success(getBalances));
    }
  } else {
    (serverResponse.message ? showErrorAlert(serverResponse.message) : showErrorAlert(serverResponse));
    yield put(walletActions.unstakeToRexRoutine.failure(serverResponse));
  }
}

function* sellRex({payload}) {
  showProcessAlert('Transaction in progress, please stand by...'); // Message
  //console.log(payload);
  const serverResponse = yield walletClientApi.multiTransaction({
    results: payload.id.data
  });
  if(serverResponse.processed) {
    showSuccessAlert('Transaction was Successful.', serverResponse.transaction_id);
    yield put(walletActions.sellRexRoutine.success(serverResponse.processed));
    const getBalances = yield getAllData({account_name: payload.id.acctName});
    if(getBalances) {
      yield put(walletActions.getAccountBalancesRoutine.success(getBalances));
    }
  } else {
    (serverResponse.message ? showErrorAlert(serverResponse.message) : showErrorAlert(serverResponse));
    yield put(walletActions.sellRexRoutine.failure(serverResponse));
  }
}

function* borrowEos({payload}) {
  showProcessAlert('Transaction in progress, please stand by...'); // Message
  //console.log(payload);
  const serverResponse = yield walletClientApi.multiTransaction({
    results: payload.id.data
  });
  if(serverResponse.processed) {
    showSuccessAlert('Transaction was Successful.', serverResponse.transaction_id);
    yield put(walletActions.borrowRexRoutine.success(serverResponse.processed));
    const getBalances = yield getAllData({account_name: payload.id.acctName});
    if(getBalances) {
      yield put(walletActions.getAccountBalancesRoutine.success(getBalances));
    }
  } else {
    (serverResponse.message ? showErrorAlert(serverResponse.message) : showErrorAlert(serverResponse));
    yield put(walletActions.borrowRexRoutine.failure(serverResponse));
  }
}

function* voteProxy({payload}) {
  showProcessAlert('Transaction in progress, please stand by...'); // Message
  //console.log(payload);
  const serverResponse = yield walletClientApi.broadcastTransaction({
    contract: 'eosio',
    method: 'voteproducer',
    data: payload.id,
  });
  if(serverResponse.processed) {
    showSuccessAlert('Transaction was Successful.', serverResponse.transaction_id);
    yield put(walletActions.borrowRexRoutine.success(serverResponse.processed));
  } else {
    (serverResponse.message ? showErrorAlert(serverResponse.message) : showErrorAlert(serverResponse));
    yield put(walletActions.borrowRexRoutine.failure(serverResponse));
  }
}

function* swapToken({payload}) {
  //console.log(payload.id);
  const serverResponse = yield walletClientApi.broadcastTransaction({
    contract: payload.id.actions[0].account,
    method: payload.id.actions[0].name,
    data: payload.id.actions[0].data,
  });
  if(serverResponse.processed) {
    //showSuccessAlert('Transaction was Successful.', serverResponse.transaction_id);
    yield put(walletActions.tokenSwapRoutine.success(serverResponse.processed));
  } else {
    (serverResponse.message ? showErrorAlert(serverResponse.message) : showErrorAlert(serverResponse));
    yield put(walletActions.tokenSwapRoutine.failure(serverResponse));
  }
}