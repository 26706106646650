/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Loader, Dimmer } from 'semantic-ui-react';

class ContentLoader extends Component {
  render() {
    const { connected, loaded } = this.props;
    const SimpleLoader = loaderSize => (
      <div>
        {/*
        <Dimmer active={!connected} inverted inline>
          <Loader indeterminate size={loaderSize} />
        </Dimmer>
        */}
        <Dimmer active={connected && !loaded} inverted>
          <Loader size={loaderSize} />
        </Dimmer>
      </div>
    );

    const loginLoader = loaderSize => (
      <div>
        <Loader active={!connected} indeterminate size={loaderSize} inline />
        <Loader active={connected && !loaded} size={loaderSize} inline />
      </div>
    );

    return (
      this.props.type === 'login' ? loginLoader(this.props.size) : SimpleLoader(this.props.size)
    );
  }
}

export default withRouter(connect(
  (state) => {
    const connected = state.data.getIn(['connected']);
    const loaded = state.data.getIn(['loaded']);

    return {
      connected,
      loaded
    };
  }
)(ContentLoader));