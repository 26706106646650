/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-access-state-in-setstate */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withUAL } from 'ual-reactjs-renderer'
import Moment from 'react-moment';
import { Image, Progress, Transition, Tab, Menu } from 'semantic-ui-react';
import EosioBody from './eosioBody';
import ContentLoader from '../../components/loader';
import { showSuccessAlert, showErrorAlert } from "../../alerts/showAlert";
import * as actions from "../../wallet/ualActions"


class EosioData extends Component {
  constructor() {
    super();
    this.state = {
      showBuyRam: false,
      sendToAccount: '',
      ramInput: '',
      ramBytesInput: '',
      activeIndex: 0,
    };
  }

  changeRamState() {
    this.setState({
      showBuyRam: !this.state.showBuyRam,
    });
  }

  onChangeInput = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  }

  buyRam = async (actionType) => {
    const { ual: { activeUser } } = this.props
    const { sendToAccount, ramInput } = this.state;
    try {
      const { accountName } = activeUser
      const contractName = 'eosio';
      const contractAction = 'buyram';
      const { requestPermission } = activeUser;
      let { permission } = activeUser;
      if (!requestPermission && activeUser.scatter) {
        permission = activeUser.scatter.identity.accounts[0].authority
      } else if (requestPermission) {
        permission = requestPermission
      }
      const ramAction = actions.getActions({type: actionType, actor: accountName, permission, contractName, contractAction, accountName: sendToAccount, quant: ramInput});
      const result = await activeUser.signTransaction(ramAction, { expireSeconds: 120, blocksBehind: 3 })
      console.info('SUCCESS:', result)
      // Show success alert with transaction link
      showSuccessAlert(result.transactionId);
    } catch (e) {
      showErrorAlert(e)
      console.error('ERROR:', e)
    }
  }

  sellRam = async (actionType) => {
    const { ual: { activeUser } } = this.props
    const { ramBytesInput } = this.state;
    try {
      const { accountName } = activeUser
      const contractName = 'eosio';
      const contractAction = 'sellram';
      const { requestPermission } = activeUser;
      let { permission } = activeUser;
      if (!requestPermission && activeUser.scatter) {
        permission = activeUser.scatter.identity.accounts[0].authority
      } else if (requestPermission) {
        permission = requestPermission
      }
      const ramAction = actions.getActions({type: actionType, actor: accountName, permission, contractName, contractAction, accountName, bytes: ramBytesInput});
      const result = await activeUser.signTransaction(ramAction, { expireSeconds: 120, blocksBehind: 3 })
      console.info('SUCCESS:', result)
      // Show success alert with transaction link
      showSuccessAlert(result.transactionId);
    } catch (e) {
      showErrorAlert(e)
      console.error('ERROR:', e)
    }
  }

  claimRefund = async () => {
    const { ual: { activeUser } } = this.props
    try {
      const { accountName } = activeUser
      const contractName = 'eosio';
      const contractAction = 'refund';
      const { requestPermission } = activeUser;
      let { permission } = activeUser;
      if (!requestPermission && activeUser.scatter) {
        permission = activeUser.scatter.identity.accounts[0].authority
      } else if (requestPermission) {
        permission = requestPermission
      }
      const refundAction = actions.getActions({type: contractAction, actor: accountName, permission, contractName, contractAction, accountName});
      const result = await activeUser.signTransaction(refundAction, { expireSeconds: 120, blocksBehind: 3 })
      console.info('SUCCESS:', result)
      // Show success alert with transaction link
      showSuccessAlert(result.transactionId);
    } catch (e) {
      showErrorAlert(e)
      console.error('ERROR:', e)
    }
  }

  handleTabChange = (e, { activeIndex }) => this.setState({ activeIndex });

  render() {
    const { showBuyRam, sendToAccount, ramInput, ramBytesInput, activeIndex } = this.state;
    const { ual: { activeUser }, ramBalance, ramPrice, ramPerToken, acctBal, TokenPrices, refundDate, refundTime, eosCpu, eosNet, eosRamMax, eosRamUsed, eosCpuTotal, eosNetTotal, eosRamTotal, netKbMax, netKbUsed, ramKbUsed, ramKbMax, refundTotal, cpuSecUsed, cpuSecMax } = this.props;
    const tokenDetails = {
      title: 'Telos ( $TLOS )',
      tagline: 'Telos: Network of the future',
      about: 'The Telos network was developed to power the economies of the future and provide human-scale solutions to global challenges. With these goals in mind, it includes innovative governance features that empower organizations to shift influence and decision-making to a more collaborative and transparent model.',
      links: [
        //remove not in use
        { url: 'https://telos.net', icon: '../assets/images/social/www.svg', name: 'website', class: 'social-link' },
        { url: 'https://medium.com/telos-foundation', icon: '../assets/images/social/medium.svg', name: 'medium', class: 'social-link' },
        { url: 'https://t.me/HelloTelos', icon: '../assets/images/social/telegram.svg', name: 'telegram', class: 'social-link' },
        { url: 'https://twitter.com/HelloTelos', icon: '../assets/images/social/twitter.svg', name: 'twitter', class: 'social-link' },
      ]
    }

    const socialLinks = items => items.map((item) => {
      return (
        // eslint-disable-next-line react/no-array-index-key
        <a href={item.url} target="_blank" rel="noopener noreferrer">
          <Image src={item.icon} alt={item.name} className={item.class} />
        </a>
      );
    });

    const panes = [
      {
        menuItem: (
          <Menu.Item key="buyRam" className="buyRam">
            <span>Buy RAM</span>
          </Menu.Item>
        ),
        render: () => <Tab.Pane attached={false}>{buyRamRender}</Tab.Pane>
      },
      {
        menuItem: (
          <Menu.Item key="sellRam" className="sellRam">
            <span>Sell RAM</span>
          </Menu.Item>
        ),
        render: () => <Tab.Pane attached={false}>{sellRamRender}</Tab.Pane>
      }
    ];

    const buyRamRender = (
      <div>
        <label className="st-input-wrap" form="eosReceiver">
          <input
            disabled={!activeUser}
            className="st-input"
            id="sendToAccount"
            type="text"
            placeholder=" "
            value={sendToAccount ? sendToAccount : activeUser?.accountName}
            onChange={this.onChangeInput}
            autoComplete="off"
          />
          <span className="st-input-label">RAM Receiver:</span>
        </label>
        <label className="st-input-wrap" form="cpuAmount">
          <input
            disabled={!activeUser}
            id="ramInput"
            className="st-input"
            type="number"
            min="0"
            placeholder=" "
            value={ramInput ? ramInput : '0.0000'}
            onChange={this.onChangeInput}
            autoComplete="off"
          />
          <span className="st-input-label__amount">Amount of RAM to Buy in TLOS</span>
        </label>
        { ramInput && (<p>≈ {(ramInput * ramPerToken)} Bytes</p>)}
        <button
          loading
          disabled={!activeUser}
          type="button"
          className="st-button st-button--purple st-button--large"
          onClick={() => { this.buyRam('buyRam') }}
        >
          {activeUser ? 'Buy Ram' : '(Connect A Wallet)'}
        </button>
      </div>
    );

    const sellRamRender = (
      <div>
        <label className="st-input-wrap" form="cpuAmount">
          <input
            disabled={!activeUser}
            id="ramBytesInput"
            className="st-input"
            type="number"
            min="0"
            placeholder=" "
            value={ramBytesInput}
            onChange={this.onChangeInput}
            autoComplete="off"
          />
          <span className="st-input-label__amount">Amount of RAM to Sell in Bytes</span>
        </label>
        <p>Available RAM: {ramBalance} {ramBytesInput && (<span> | Selling: <span style={{color: 'var(--primary)'}}>{(ramBytesInput).toLocaleString()}</span> Bytes of RAM for <span style={{color: 'var(--primary)'}}>{(ramPrice * ramBytesInput / 1000).toFixed(4)}  TLOS</span></span>)}</p>
        <button
          loading
          disabled={!activeUser}
          type="button"
          className="st-button st-button--purple st-button--large"
          onClick={() => { this.sellRam('sellRam') }}
        >
          {activeUser ? 'Sell Ram' : '(Connect A Wallet)'}
        </button>
      </div>
    );

    return (
      <div className="st-main-body-inner">
        <div className="row">
          <div className="col col-12 col-md-12 col-lg-4 st-card-col">
            <div className="st-card st-card__minheight">
              <h5 className="st-card__headline">{tokenDetails.tagline}</h5>
              <div className="st-card__token-info">
                <Image src={process.env.PUBLIC_URL + "/assets/logos/tlos-logo.svg"} alt={process.env.REACT_APP_TOKEN_NAME} /> <span className="st-card__token-name">{tokenDetails.title}</span>
              </div>
              <div className="st-card__prices">
                <div className="divider-horizontal" />
                <div className="st-card__price">
                  <h5>Price</h5>
                  <p>${TokenPrices ? TokenPrices.price.toFixed(4) : '0.00'}</p>
                </div>
                <div className="st-card__price-min">
                  <Image src={process.env.PUBLIC_URL + "/assets/images/min-arrow.svg"} alt="min" />
                  <div>
                    <h5>MIN 24H</h5>
                    <p>${TokenPrices ? TokenPrices.low.toFixed(4) : '0.00'}</p>
                  </div>
                </div>
                <div className="st-card__price-max">
                  <Image src={process.env.PUBLIC_URL + "/assets/images/max-arrow.svg"} alt="max" />
                  <div>
                    <h5>MAX 24H</h5>
                    <p>${TokenPrices ? TokenPrices.high.toFixed(4) : '0.00'}</p>
                  </div>
                </div>
              </div>
              <div className="st-card__description">
                {tokenDetails.about}
              </div>
              <div className="st-card__socials">
                {socialLinks(tokenDetails.links)}
              </div>
            </div>
          </div>
          <div className="col col-12 col-md-12 col-lg-8 st-card-col">
            <div className="st-card st-resources">
              <div className="st-resources__side">
                <h5 className="st-card__headline">BALANCES</h5>
                <Image className="st-resources__icon" src={process.env.PUBLIC_URL + "/assets/images/dropp-arrow-icon.svg"} alt="min" />
                <h4 className="st-resources__title">AVAILABLE</h4>
                <h3 className="st-resources__balance">{acctBal}</h3>
                <h5 className="st-card__headline">RESOURCES</h5>
                <ul className="st-resources__info list-reset">
                  <li>
                    <h5>CPU</h5>
                    <p>{eosCpu ? eosCpu : '0.00 TLOS'}</p>
                  </li>
                  <li>
                    <h5>NET</h5>
                    <p>{eosNet ? eosNet : '0.00 TLOS'}</p>
                  </li>
                  <li>
                    <h5>RAM</h5>
                    <p>{(eosRamUsed && eosRamMax) ? `${eosRamMax - eosRamUsed} Bytes` : '0 Bytes'}</p>
                  </li>
                  <li>
                    <h5>RAM Price</h5>
                    <p>{ramPrice !== isNaN ? `${ramPrice} TLOS/KB` : '0.00 TLOS/KB'}</p>
                  </li>

                  <li>
                    <h5>REFUNDING</h5>
                    <p>{refundTotal + ' TLOS'}
                      <br />
                      <small>{refundDate ? <small>You can claim on: <Moment date={refundTime} format="YYYY/MM/DD HH:mm" /></small> : <small>No Pending Refund</small>}</small>
                      <button
                        hidden={!refundDate}
                        type="button"
                        className="st-button st-button__tiny btn-outline-primary"
                        onClick={() => { this.claimRefund() }}
                      >
                        Claim Refund
                      </button>
                    </p>
                  </li>
                  {/*
                  <li>
                    <h5>TOTAL Telos</h5>
                    <p>{totalRexEos ? totalRexEos + ' TLOS' : '0.00 TLOS'}</p>
                  </li>
                  */}
                </ul>
              </div>
              <div className="resources__sideRight">
                <h5 className="st-card__headline">Resource utilization</h5>
                <div className="st-resources__main">
                  <ul className="st-indicators list-reset">
                    <li className="st-indicator">
                      <div className="st-indicator__left">
                        <Image className="st-indicator__icon" src={process.env.PUBLIC_URL + "/assets/images/cpu-icon.svg"} alt="CPU" />
                      </div>
                      <div className="st-indicator__right">
                        <h4 className="st-indicator__title">CPU</h4>
                        <Progress percent={eosCpuTotal} active />
                        <p className="st-indicator__info">{cpuSecUsed} / {cpuSecMax}</p>
                      </div>
                    </li>
                    <li className="st-indicator">
                      <div className="st-indicator__left">
                        <Image className="st-indicator__icon" src={process.env.PUBLIC_URL + "/assets/images/net-icon.svg"} alt="NET" />
                      </div>
                      <div className="st-indicator__right">
                        <h4 className="st-indicator__title">NET</h4>
                        <Progress percent={eosNetTotal} active />
                        <p className="st-indicator__info">{netKbUsed}/ {netKbMax}</p>
                      </div>
                    </li>
                    <li className="st-indicator" id="ram">
                      <div className="st-indicator__left">
                        <Image className="st-indicator__icon" src={process.env.PUBLIC_URL + "/assets/images/ram-icon.svg"} alt="RAM" />
                      </div>
                      <div className="st-indicator__right">
                        <h4 className="st-indicator__title">RAM {isNaN(eosRamTotal) ? 0 : eosRamTotal}%</h4>
                        <Progress percent={eosRamTotal} active indicating />
                        <p className="st-indicator__info">{ramKbUsed}/ {ramKbMax}</p>
                      </div>
                    </li>
                    <button
                      type="button"
                      className="st-button btn-outline-primary st-button--large"
                      onClick={this.changeRamState.bind(this)}
                    >
                      Manage RAM
                    </button>
                  </ul>
                </div>
              </div>
              <ContentLoader size="massive" />
            </div>
          </div>
        </div>
        <Transition visible={showBuyRam} animation="scale" duration={700}>
          <div className="row">
            <div className="col col-12 col-md-12 st-card-col">
              <div className="st-card">
                <Tab menu={{ secondary: true, pointing: true, widths: 2, size: 'massive' }} panes={panes} activeIndex={activeIndex} onTabChange={this.handleTabChange} />
              </div>
            </div>
          </div>
        </Transition>
        <EosioBody />
      </div>
    );
  }
}

export default withUAL(withRouter(connect(
  (state) => {
    const acctBal = state.data.getIn(['accountInfo', 'accountRows', 'core_liquid_balance'], 0);
    const ramBase = state.data.getIn(['accountInfo', 'ramPrice', 'rows', 0, 'base', 'balance'], 0);
    const ramQuote = state.data.getIn(['accountInfo', 'ramPrice', 'rows', 0, 'quote', 'balance'], 0);
    const ramPrice = parseFloat(parseFloat(ramQuote).toFixed(4) / parseFloat(ramBase) * 1024).toFixed(4);
    const ramPerToken = parseFloat(parseFloat(ramBase).toFixed(4) / parseFloat(ramQuote).toFixed(4)).toFixed(4);
    const refundDate = state.data.getIn(['accountInfo', 'eosRows', 'refund', 'rows', 0, 'request_time'], 0);
    const refundFuture = new Date(refundDate);
    const refundTime = refundFuture.setDate(refundFuture.getDate() + parseInt(4));
    const refundCpu = state.data.getIn(['accountInfo', 'eosRows', 'refund', 'rows', 0, 'cpu_amount'], 0);
    const refundNet = state.data.getIn(['accountInfo', 'eosRows', 'refund', 'rows', 0, 'net_amount'], 0);
    const eosCpu = state.data.getIn(['accountInfo', 'accountRows', 'total_resources', 'cpu_weight'], 0);
    const eosNet = state.data.getIn(['accountInfo', 'accountRows', 'total_resources', 'net_weight'], 0);
    const eosCpuUsed = state.data.getIn(['accountInfo', 'accountRows', 'cpu_limit', 'used'], 0);
    const eosCpuMax = state.data.getIn(['accountInfo', 'accountRows', 'cpu_limit', 'max'], 0);
    const eosNetUsed = state.data.getIn(['accountInfo', 'accountRows', 'net_limit', 'used'], 0);
    const eosNetMax = state.data.getIn(['accountInfo', 'accountRows', 'net_limit', 'max'], 0);
    const eosRamUsed = state.data.getIn(['accountInfo', 'accountRows', 'ram_usage'], 0);
    const eosRamMax = state.data.getIn(['accountInfo', 'accountRows', 'ram_quota'], 0);
    const rexBal = state.data.getIn(['accountInfo', 'rexRows', 'rows', 0, 'vote_stake'], 0);
    const rexFund = state.data.getIn(['accountInfo', 'rexRows', 'rexFunds', 'rows', 0, 'balance'], 0);
    const totalRexEos = (parseFloat(rexFund) + parseFloat(rexBal)).toFixed(4);
    const refundTotal = (parseFloat(refundCpu) + parseFloat(refundNet)).toFixed(4);

    const eosCpuTotal = parseFloat(eosCpuUsed / eosCpuMax * 100).toFixed(2);
    const eosNetTotal = parseFloat(eosNetUsed / eosNetMax * 100).toFixed(2);
    const eosRamTotal = Math.round(eosRamUsed / eosRamMax * 100).toFixed(2);

    const ramBalance = parseFloat(eosRamMax - eosRamUsed).toFixed(0)

    function formatBytes(bytes) {
      if (bytes < 1024) return bytes + ' Bytes';
      else if (bytes < 1048576) return (bytes / 1024).toFixed(2) + ' KB';
      else if (bytes < 1073741824) return (bytes / 1048576).toFixed(2) + ' MB';
      else return (bytes / 1073741824).toFixed(2) + ' GB';
    }

    function formatCpu(secs) {
      if (secs < 1000) return secs + ' µs';
      else if (secs < 1000000) return (secs / 1000).toFixed(2) + ' ms';
      else if (secs < 1000000000) return (secs / 1000000).toFixed(2) + ' s';
      else if (secs < 10000000000) return (secs / 1000000000).toFixed(2) + ' min';
      else if (secs < 100000000000) return (secs / 10000000000).toFixed(2) + ' hour';
      else if (secs < 1000000000000) return (secs / 100000000000).toFixed(2) + ' days';
      else return (secs / 10000000000000).toFixed(2) + ' weeks';
    }

    const netKbUsed = formatBytes(eosNetUsed);
    const netKbMax = formatBytes(eosNetMax);
    const ramKbUsed = formatBytes(eosRamUsed);
    const ramKbMax = formatBytes(eosRamMax);
    const cpuSecUsed = formatCpu(eosCpuUsed);
    const cpuSecMax = formatCpu(eosCpuMax);

    return {
      acctBal,
      ramPrice,
      ramPerToken,
      refundDate,
      refundTime,
      eosCpu,
      eosNet,
      eosCpuUsed,
      eosCpuMax,
      eosCpuTotal,
      eosNetUsed,
      eosNetMax,
      eosNetTotal,
      eosRamUsed,
      eosRamMax,
      eosRamTotal,
      netKbMax,
      netKbUsed,
      ramKbUsed,
      ramKbMax,
      refundTotal,
      cpuSecUsed,
      cpuSecMax,
      totalRexEos,
      ramBalance
    };
  },
)(EosioData)));