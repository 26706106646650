/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withUAL } from 'ual-reactjs-renderer'
import { List } from 'immutable';
import { Table, Popup } from 'semantic-ui-react';
import { showSuccessAlert, showErrorAlert } from "../../alerts/showAlert";
import * as actions from "../../wallet/ualActions"

class EosioBody extends Component {
  constructor() {
    super();
    this.state = {
      sendToAccount: '',
      unstakeFrom: '',
      cpuStakeAmount: '',
      netStakeAmount: '',
      cpuUnstakeAmount: '',
      netUnstakeAmount: ''
    };
  }

  updateStake = async (actionType) => {
    const { ual: { activeUser } } = this.props
    const { sendToAccount, unstakeFrom, netStakeAmount, cpuStakeAmount, netUnstakeAmount, cpuUnstakeAmount } = this.state;
    try {
      const { accountName } = activeUser
      const contractName = "eosio";
      const contractAction = actionType === 'stake' ? 'delegatebw' : 'undelegatebw';
      const { requestPermission } = activeUser;
      let { permission } = activeUser;
      if (!requestPermission && activeUser.scatter) {
        permission = activeUser.scatter.identity.accounts[0].authority
      } else if (requestPermission) {
        permission = requestPermission
      }
      const stakeAction = actions.getActions({type: actionType, actor: accountName, permission, contractName, contractAction, accountName: actionType === 'stake' ? sendToAccount : unstakeFrom, netAmount: actionType === 'stake' ? netStakeAmount : netUnstakeAmount, cpuAmount: actionType === 'stake' ? cpuStakeAmount : cpuUnstakeAmount});
      const result = await activeUser.signTransaction(stakeAction, { expireSeconds: 120, blocksBehind: 3 })
      console.info('SUCCESS:', result)
      // Show success alert with transaction link
      showSuccessAlert(result.transactionId);
    } catch (e) {
      showErrorAlert(e)
      console.error('ERROR:', e)
    }
  }

  onChangeInput = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  }

  render() {
    const { cpuStakeAmount, netStakeAmount, sendToAccount, unstakeFrom, cpuUnstakeAmount, netUnstakeAmount } = this.state;
    const { eosBal, ual: { activeUser } } = this.props;
    const acctName = activeUser ? this.props.ual.activeUser.accountName : null;
    const stakedAccounts = this.props.stakedAccounts.toJS();
    const stakeDefault = '0.0000';
    const stakedData = [];
    const renderStakes = items => items.map((item, index) => {
      return (
        // eslint-disable-next-line react/no-array-index-key
        <Table.Row key={item + index}>
          <Table.Cell>
            {item.to}
          </Table.Cell>
          <Table.Cell>
            {item.cpu_weight}
          </Table.Cell>
          <Table.Cell>
            {item.net_weight}
          </Table.Cell>
        </Table.Row>
      );
    });

    stakedAccounts.forEach((obj) => {
      obj.holder = obj.to;
      obj.cpu = obj.cpu_weight;
      obj.net = obj.net_weight;
      stakedData.push(obj);
    });

    return (
      <div>
        <div className="row">
          <div className="col col-12 col-md-6 st-card-col" id="stake">
            <div className="st-card">
              <h5 className="st-card__headline">STAKE TLOS</h5>
              <label className="st-input-wrap" form="eosReceiver">
                <input
                  disabled={!acctName}
                  className="st-input"
                  id="sendToAccount"
                  type="text"
                  placeholder=" "
                  value={sendToAccount ? sendToAccount : acctName}
                  onChange={this.onChangeInput}
                  autoComplete="off"
                />
                <span
                  className="st-input-label"
                  style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}
                >
                  <span>To</span>
                  <span>Defaults to selected account</span>
                </span>
              </label>
              <div className="row">
                <div className="col col-12 col-md-6">
                  <label className="st-input-wrap" form="cpuAmount">
                    <input
                      disabled={!acctName}
                      id="cpuStakeAmount"
                      className="st-input"
                      type="number"
                      min="0"
                      placeholder={stakeDefault}
                      value={cpuStakeAmount}
                      onChange={this.onChangeInput}
                      autoComplete="off"
                    />
                    <span className="st-input-label__amount">Add CPU</span>
                  </label>
                </div>
                <div className="col col-12 col-md-6">
                  <label className="st-input-wrap" form="netAmount">
                    <input
                      disabled={!acctName}
                      id="netStakeAmount"
                      className="st-input"
                      type="number"
                      min="0"
                      placeholder={stakeDefault}
                      value={netStakeAmount}
                      onChange={this.onChangeInput}
                      autoComplete="off"
                    />
                    <span className="st-input-label__amount">Add NET</span>
                  </label>
                </div>
              </div>
              <button
                loading
                disabled={!acctName}
                type="button"
                className="st-button st-button--purple st-button--large"
                onClick={() => {
                  parseFloat(parseFloat(cpuStakeAmount).toFixed(4)) > parseFloat(parseFloat(eosBal).toFixed(8))
                    || parseFloat(parseFloat(netStakeAmount).toFixed(4)) > parseFloat(parseFloat(eosBal).toFixed(8))
                    || parseFloat(parseFloat(netStakeAmount).toFixed(4)) + parseFloat(parseFloat(cpuStakeAmount).toFixed(8)) > parseFloat(parseFloat(eosBal).toFixed(8))
                    ? showErrorAlert(`You are trying to stake more then you have!`)
                    : this.updateStake('stake');
                }}> {acctName ? 'Stake' : '(Connect A Wallet)'}
              </button>
            </div>
          </div>
          <div className="col col-xs-12 col-md-6 st-card-col" id="unstake">
            <div className="st-card">
              <h5 className="st-card__headline">UNSTAKE TLOS</h5>
              <Popup
                content="Refund Time: 3 Days"
                position="top right"
                trigger={
                  <img className="st-card__popup" src={process.env.PUBLIC_URL + "/assets/images/info-icon.svg"} alt="info" />
                }
              />
              <label className="st-input-wrap" form="eosUnstakeReceiver">
                <input
                  disabled={!acctName}
                  id="unstakeFrom"
                  className="st-input"
                  type="text"
                  placeholder=" "
                  value={unstakeFrom ? unstakeFrom : acctName}
                  onChange={this.onChangeInput}
                  autoComplete="off"
                />
                <span
                  className="st-input-label"
                  style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}
                >
                  <span>From</span>
                  <span>Defaults to selected account</span>
                </span>
              </label>
              <div className="row">
                <div className="col col-12 col-md-6">
                  <label className="st-input-wrap" form="cpuUnstakeAmount">
                    <input
                      disabled={!acctName}
                      id="cpuUnstakeAmount"
                      className="st-input"
                      type="number"
                      placeholder={stakeDefault}
                      value={cpuUnstakeAmount}
                      onChange={this.onChangeInput}
                      autoComplete="off"
                    />
                    <span className="st-input-label__amount">Remove CPU</span>
                  </label>
                </div>
                <div className="col col-12 col-md-6">
                  <label className="st-input-wrap" form="netUnstakeAmount">
                    <input
                      disabled={!acctName}
                      id="netUnstakeAmount"
                      className="st-input"
                      type="number"
                      placeholder={stakeDefault}
                      value={netUnstakeAmount}
                      onChange={this.onChangeInput}
                      autoComplete="off"
                    />
                    <span className="st-input-label__amount">Remove NET</span>
                  </label>
                </div>
              </div>
              <button
                disabled={!acctName}
                type="button"
                className="st-button st-button--purple st-button--large"
                onClick={() => {
                  this.updateStake('unstake');
                }}> {acctName ? 'Unstake' : '(Connect A Wallet)'}
              </button>
            </div>
          </div>
          <div className="col col-12 col-md-12 st-card-col">
            <div className="st-card">
              <h5 className="st-card__headline">Available/Delegated Resources</h5>
              {stakedAccounts[0] && (
                <Table singleLine>
                  <Table.Header>
                    <Table.Row>
                      <Table.Cell>TO</Table.Cell>
                      <Table.Cell>CPU</Table.Cell>
                      <Table.Cell>NET</Table.Cell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {renderStakes(stakedData)}
                  </Table.Body>
                </Table>
              )}
              {!stakedAccounts[0] && (
                <p>Nothing yet.</p>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withUAL(withRouter(connect(
  (state) => {
    const acctName = state.data.getIn(['identity', 'account_name']);
    const eosBal = state.data.getIn(['accountInfo', 'accountRows', 'core_liquid_balance'], 0);
    const eosCpu = state.data.getIn(['accountInfo', 'accountRows', 'self_delegated_bandwidth', 'cpu_weight'], 0);
    const eosNet = state.data.getIn(['accountInfo', 'accountRows', 'self_delegated_bandwidth', 'net_weight'], 0);
    const eosCpuUsed = state.data.getIn(['accountInfo', 'accountRows', 'cpu_limit', 'used'], 0);
    const eosCpuMax = state.data.getIn(['accountInfo', 'accountRows', 'cpu_limit', 'max'], 0);
    const eosNetUsed = state.data.getIn(['accountInfo', 'accountRows', 'net_limit', 'used'], 0);
    const eosNetMax = state.data.getIn(['accountInfo', 'accountRows', 'net_limit', 'max'], 0);
    const eosRamUsed = state.data.getIn(['accountInfo', 'accountRows', 'ram_usage'], 0);
    const eosRamMax = state.data.getIn(['accountInfo', 'accountRows', 'ram_quota'], 0);
    const stakedAccounts = state.data.getIn(['accountInfo', 'eosStakedRows', 'rows'], List());

    const eosCpuTotal = parseFloat(eosCpuUsed / eosCpuMax * 100).toFixed(2);
    const eosNetTotal = parseFloat(eosNetUsed / eosNetMax * 100).toFixed(2);
    const eosRamTotal = Math.round(eosRamUsed / eosRamMax * 100).toFixed(2);

    return {
      acctName,
      eosBal,
      eosCpu,
      eosNet,
      eosCpuUsed,
      eosCpuMax,
      eosCpuTotal,
      eosNetUsed,
      eosNetMax,
      eosNetTotal,
      eosRamUsed,
      eosRamMax,
      eosRamTotal,
      stakedAccounts
    };
  }
)(EosioBody)));