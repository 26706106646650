import { initAccessContext } from 'eos-transit';
import anchorLink from 'eos-transit-anchorlink-provider';
//import scatter from 'eos-transit-scatter-provider';
import wombat from 'eos-transit-tokenpocket-provider';
import { withUAL } from 'ual-reactjs-renderer'
import { showErrorAlert, showLoader, showConnected } from "../alerts/showAlert";

let walletapi;
const accessContext = initAccessContext({
  appName: 'telos.staker.one',
  network: {
    host: 'mainnet.telos.net',
    port: process.env.REACT_APP_WALLET_PORT,
    protocol: process.env.REACT_APP_WALLET_PROTOCOL,
    chainId: process.env.REACT_APP_WALLET_CHAIN_ID
  },
  walletProviders: [
    anchorLink(process.env.REACT_APP_NETWORK),
    //scatter(),
    wombat(),
  ]
});

export function getWallet() {
  return accessContext.walletProviders;
}

export function* connectToWallet() {
  try {
    showLoader()
    const connected = yield walletapi.connect();
    return connected;
  } catch (e) {
    //console.log(e)
    showErrorAlert(e)
    return { isError: true };
  }
}

export function* getWalletIdentity() {
  try {
    const identity = yield walletapi.login();
    return identity;
  } catch (e) {
    return e;
  }
}

export function* linkWallet() {
  //const identity = yield getWalletIdentity();
  try {
    const linked = yield walletapi.auth.accountName;
    //console.log(linked)
    showConnected(linked);
    return linked;
  } catch (e) {
    return { isError: true };
  }
}

export function* forgetIdentity() {
  const forgotten = yield walletapi.logout();
  return forgotten;
}

export function* getTableRows(query) {
  return yield walletapi.eosApi.getTableRows(query);
}

export function* test() { yield withUAL(broadcastTransaction()) }
export function* broadcastTransaction({ contract, method, data }) {
  //yield connectToWallet();
  //yield linkWallet();
  try {
    //const account = yield wallet.login('account_name');
    const res = yield this.props.ual.activeUser.signTransaction(
      {
        actions: [
          {
            account: contract,
            name: method,
            authorization: [{
              actor: walletapi.auth.accountName,
              permission: walletapi.auth.permission
            }],
            data
          }
        ]
      },
      {
        broadcast: true,
        blocksBehind: 3,
        expireSeconds: 60
      }
    );
    return res;
  } catch (e) {
    return e;
  }
}

export function* multiTransaction({ results }) {
  const sortActions = [];

  results.forEach(result => [
    sortActions.push({
      account: result.contract,
      name: result.method,
      authorization: [
        {
          actor: walletapi.auth.accountName,
          permission: walletapi.auth.permission
        }
      ],
      data: result.fields[0],
    })
  ]);

  try {
    const res = yield walletapi.eosApi
      .transact(
        {
          actions: sortActions
        },
        {
          broadcast: true,
          blocksBehind: 3,
          expireSeconds: 60
        }
      );
    return res;
  } catch (e) {
    //console.log(e);
    return e;
  }
}