import axios from 'axios';

export function* getRestApi() {
  const RestApi = {};
  try {
    const RexApr = yield axios.get(`${process.env.REACT_APP_TELOS_APR}`, {});
    console.log(RexApr)
    RestApi.data = RexApr.data.data;
  } catch (e) {
    console.error(e);
  }
  return RestApi;
}