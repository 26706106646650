import Swal from 'sweetalert2';

export const showCustomAlert = (message) => {
  Swal.fire({
    text: message,
    timer: 8000,
    showConfirmButton: false,
    showCancelButton: false,
    showCloseButton: true,
  });
};

export const showLoader = () => {
  Swal.fire({
    title: '<strong>Connecting To Wallet</strong>',
    type: 'info',
    showCloseButton: true,
    onBeforeOpen: () => {
      Swal.showLoading()
    },
  });
};

export const showErrorAlert = (message) => {
  Swal.fire({
    title: '<strong>Error</strong>',
    text: message,
    type: 'error',
    timer: 2500,
    showConfirmButton: false,
    showCancelButton: false,
    showCloseButton: true,
  });
};

export const showConnected = (name) => {
  Swal.fire({
    title: `Connected To Account`,
    text: name,
    type: 'success',
    timer: 2500,
    showConfirmButton: false,
    showCancelButton: false,
    showCloseButton: true
  });
};

export const showRequest = (message) => {
  Swal.fire({
    type: 'info',
    title: 'Requesting Wallet Identity!',
    text: message,
    timer: 5000,
    showCloseButton: true
  });
};

export const showProcessAlert = () => {
  Swal.fire({
    title: 'Transaction in progress',
    text: 'waiting for approval',
    type: 'info',
    showCloseButton: true,
    onBeforeOpen: () => {
      Swal.showLoading()
    },
  });
};

export const showSuccessAlert = (transaction) => {
  Swal.fire({
    title: 'Transaction was Successful!',
    html: `<small><a href="${process.env.REACT_APP_BLOKS_URL + transaction}" target="_blank">${transaction}</a></small>`,
    type: 'success',
    showConfirmButton: false,
    showCancelButton: false,
    showCloseButton: true,
  });
};