import { createRoutine } from 'redux-saga-routines';

// creating routine
export const connectToWalletRoutine = createRoutine('Wallet/CONNECT');
export const signInToWalletRoutine = createRoutine('Wallet/SIGN_IN');
export const signOutOfWalletRoutine = createRoutine('Wallet/SIGN_OUT');

export const voteProxyRoutine = createRoutine('Wallet/VOTE_PROXY');

export const getAccountBalancesRoutine = createRoutine('Wallet/GET_ACCOUNT_BALANCES');

export const connectedRoutine = createRoutine('Wallet/ACCOUNT_CONNECTED');
export const dataLoadedRoutine = createRoutine('Wallet/DATA_LOADED');

export const claimAirHodlRoutine = createRoutine('Wallet/CLAIM_AIRHODL');

export const transferEosRoutine = createRoutine('Wallet/TRANSFER_EOS');
export const stakeEosRoutine = createRoutine('Wallet/STAKE_EOS');
export const unStakeEosRoutine = createRoutine('Wallet/UNSTAKE_EOS');

export const lendRexRoutine = createRoutine('Wallet/LEND_REX');
export const unstakeToRexRoutine = createRoutine('Wallet/LEND_REX_NET');
export const sellRexRoutine = createRoutine('Wallet/SELL_REX');
export const borrowRexRoutine = createRoutine('Wallet/BORROW_REX');

export const tokenSwapRoutine = createRoutine('Wallet/TOKEN_SWAP');

//REST API
export const restApiRoutine = createRoutine('Wallet/GET_RESTAPI');

//Fetch Messages
export const getMessagesRoutine = createRoutine('Wallet/GET_MESSAGES');
