/* eslint-disable react/destructuring-assignment */
/* eslint-disable prefer-const */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { List } from 'immutable';
import { Table, Header } from 'semantic-ui-react';
import { getTokenPrice } from '../helpers/sortPrices';
import * as walletActions from '../../wallet/walletActions';

class Account extends Component {
  constructor() {
    super();
    this.state = {
      loaded: false
    };
  }

  componentDidMount() {
    const { acctName, restApi } = this.props;

    if (acctName) {
      restApi();
    }
  }

  componentWillUnmount() {
  }

  componentWillUpdate() {
    const { acctName, restApi } = this.props;
    const { loaded } = this.state;

    if (acctName && !loaded) {
      restApi();
      this.changeLoadState(true)
    }
  }

  changeLoadState = (event) => {
    this.setState({ loaded: event });
  };

  render() {
    const { acctName, totalAcctValue, eosAcctValue, tokenList } = this.props;
    const renderStakes = items => items.map((item, index) => {
      return (
        // eslint-disable-next-line react/no-array-index-key
        <Table.Row key={item.token + index}>
          <Table.Cell style={{ textTransform: 'uppercase' }}>
            {item.name}
          </Table.Cell>
          <Table.Cell>
            {item.amount}
          </Table.Cell>
          <Table.Cell style={{ textTransform: 'uppercase' }}>
            {item.price}
          </Table.Cell>
        </Table.Row>
      );
    });
    return (
      <div className="st-main-body-inner">
        <div className="row">

          <div className="col col-12 st-card-col">
            <div className="st-card" style={{ backgroundColor: '#0077ff', color: '#fff' }}>
              <Header as="h1">
                <Header.Content style={{ color: '#fff' }}>
                  Portfolio:
                </Header.Content>
              </Header>
              <h1 style={{ color: '#fff', textTransform: 'uppercase' }}>{eosAcctValue} {process.env.REACT_APP_NETWORK} / <small>$ {totalAcctValue.toFixed(2)}</small></h1>
            </div>
          </div>

          <div className="col col-12 st-card-col">
            <div className="st-card">
              <h1>Tokens</h1>
              <Table singleLine striped style={{ paddingBottom: '5rem' }} dimmed>
                <Table.Header>
                  <Table.Row>
                    <Table.Cell>Token:</Table.Cell>
                    <Table.Cell>Amount:</Table.Cell>
                    <Table.Cell>Price:</Table.Cell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {!acctName && (
                    <Table.Row>
                      <Table.Cell>
                        .....
                      </Table.Cell>
                      <Table.Cell>
                        .....
                      </Table.Cell>
                      <Table.Cell>
                        .....
                      </Table.Cell>
                    </Table.Row>
                  )}
                  {acctName && (tokenList.length > 0 ? (renderStakes(tokenList)) : 'Error Loading Tokens')}
                </Table.Body>
              </Table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(connect(
  (state) => {
    const eosTokens = (parseFloat(state.data.getIn(['accountInfo', 'eosRows', 'rows', 0, 'balance'], 0)) + parseFloat(state.data.getIn(['accountInfo', 'accountRows', 'self_delegated_bandwidth', 'cpu_weight'], 0)) + parseFloat(state.data.getIn(['accountInfo', 'accountRows', 'self_delegated_bandwidth', 'net_weight'], 0)));
    const tokenNames = [{ name: 'TLOS', row: 'eosRows' }];
    const acctName = state.data.getIn(['identity', 'account_name']);
    const tokenPrices = state.prices.getIn(['prices', 'data'], List()).toJS();

    let eosPrice;

    eosPrice = getTokenPrice(tokenPrices, process.env.REACT_APP_NETWORK);

    const tokenList = [];

    const sortTokenData = items => items.map((token) => {
      return (
        // eslint-disable-next-line react/no-array-index-key
        tokenList.push({ name: token.name, amount: (eosTokens).toFixed(4), price: `$${eosPrice ? parseFloat(eosTokens * eosPrice.price).toFixed(2) : 0}` })
      );
    });
    sortTokenData(tokenNames)

    //Total Holdings
    let acctValue = 0;
    const totalAcctValue = (acctValue
      + parseFloat(eosTokens) * parseFloat(eosPrice ? eosPrice.price : 0));

    //Eos Total
    const eosValue = eosPrice && parseFloat(eosPrice.price).toFixed(7);
    const eosAcctValue = (totalAcctValue / eosValue).toFixed(4);
    return {
      acctName,
      tokenList,
      eosPrice,
      totalAcctValue,
      eosAcctValue,
    };
  },
  dispatch => ({
    restApi: id => dispatch(walletActions.restApiRoutine({ id }))
  })
)(Account));