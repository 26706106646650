import axios from 'axios';

const resultObj = {};
const url = 'mainnet.telos.net';


export function* getAllData({ account_name }) {
  yield getEosRamPrice();
  yield getEosioData({ account_name });
  yield getEosRexData({ account_name });
  return resultObj;
}

//============
// Get RamPrice
//============
export function* getEosRamPrice() {
  //const resultObj = {};
  try {
    const ramPrice = yield axios.post(`https://${url}/v1/chain/get_table_rows`, {
      json: 'true',
      scope: 'eosio',
      code: 'eosio',
      table: 'rammarket',
    });
    resultObj.ramPrice = ramPrice.data;
  } catch (e) {
    console.error('Error Retrieving REX Data', e);
  }
  return resultObj;
}

export function* getEosioData({ account_name }) {
  //============
  // EOS
  //============
  try {
    const eosBalance = yield axios.post(`https://${url}/v1/chain/get_table_rows`, {
      json: 'true',
      scope: account_name,
      code: 'eosio.token',
      table: 'accounts',
    });
    resultObj.eosRows = eosBalance.data;
  } catch (e) {
    console.error('Error retrieving eosBalance', e);
  }

  try {
    const eosStakedBalance = yield axios.post(`https://${url}/v1/chain/get_table_rows`, {
      json: 'true',
      scope: account_name,
      code: 'eosio',
      table: 'delband',
    });
    resultObj.eosStakedRows = eosStakedBalance.data;
  } catch (e) {
    console.error('Error retrieving eosStakedBalance', e);
  }

  try {
    const getAccount = yield axios.post(`https://${url}/v1/chain/get_account`, {
      account_name,
    });
    resultObj.accountRows = getAccount.data;
  } catch (e) {
    console.error('Error retrieving getAccount', e);
  }

  try {
    const eosRefund = yield axios.post(`https://${url}/v1/chain/get_table_rows`, {
      json: 'true',
      scope: account_name,
      code: 'eosio',
      table: 'refunds',
    });
    resultObj.eosRows.refund = eosRefund.data;
  } catch (e) {
    console.error('Error retrieving eos refund', e);
  }

  try {
    const eosNetLoans = yield axios.post(`https://${url}/v1/chain/get_table_rows`, {
      json: 'true',
      scope: 'eosio',
      code: 'eosio',
      table: 'netloan',
      index_position: 3,
      key_type: 'name',
      lower_bound: account_name,
      upper_bound: account_name
    });
    resultObj.eosRows.netloans = eosNetLoans.data;
  } catch (e) {
    console.error('Error retrieving eos net loan', e);
  }

  try {
    const eosCpuLoans = yield axios.post(`https://${url}/v1/chain/get_table_rows`, {
      json: 'true',
      scope: 'eosio',
      code: 'eosio',
      table: 'cpuloan',
      index_position: 3,
      key_type: 'name',
      lower_bound: account_name,
      upper_bound: account_name
    });
    resultObj.eosRows.cpuloans = eosCpuLoans.data;
  } catch (e) {
    console.error('Error retrieving eos cpu loan', e);
  }
  return resultObj;
}


//============
// EOSIO REX
//============
export function* getEosRexData({ account_name }) {
  //const resultObj = {};
  try {
    const rexBalance = yield axios.post(`https://${url}/v1/chain/get_table_rows`, {
      json: 'true',
      scope: 'eosio',
      code: 'eosio',
      table: 'rexbal',
      lower_bound: account_name,
      upper_bound: account_name
    });
    resultObj.rexRows = rexBalance.data;

    const rexFund = yield axios.post(`https://${url}/v1/chain/get_table_rows`, {
      json: 'true',
      scope: 'eosio',
      code: 'eosio',
      table: 'rexfund',
      lower_bound: account_name,
      upper_bound: account_name
    });
    resultObj.rexRows.rexFunds = rexFund.data;

    const rexPool = yield axios.post(`https://${url}/v1/chain/get_table_rows`, {
      json: 'true',
      scope: 'eosio',
      code: 'eosio',
      table: 'rexpool'
    });
    resultObj.rexRows.rexPool = rexPool.data;
  } catch (e) {
    console.error('Error Retrieving REX Data', e);
  }
  return resultObj;
}