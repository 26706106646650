/* eslint-disable */
import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { Anchor } from 'ual-anchor'
//import { KeycatAuthenticator } from '@telosnetwork/ual-telos-keycat'
//import { Scatter } from 'ual-scatter'
import { Wombat } from 'ual-wombat'
import { UALProvider } from 'ual-reactjs-renderer'

import configureStore from './app/redux/configureStore';
import MainBody from './app/components/main';
//import AppHeader from './app/components/login.componet';
import './style/all.scss';
import 'semantic-ui-css/semantic.min.css';
import 'animate.css';

const store = configureStore();

const chains = [{
  chainId: '4667b205c6838ef70ff7988f6e8257e8be0e1284a2f59699054a018f743b1d11',
  name: 'TELOS',
  rpcEndpoints: [{
    protocol: 'https',
    host: 'mainnet.telos.net',
    port: 443,
  }]
}];

class UALWrapper extends Component {
  constructor(props) {
    super(props)
    const search = window.location.search
    const params = new URLSearchParams(search)
    const chainId = params.get('chain')
    this.state = {
      chainId: chainId || chains[0].chainId,
    }
  }

  setChain = (event) => this.setState({ chainId: event.target.value });

  render () {
    const { chainId } = this.state
    const { available } = this.props
    const [ chain ] = available.filter((c) => c.chainId === chainId)
    if (!chain) {
      return (
        <div>Invalid Chain ID</div>
      )
    }
    const anchor = new Anchor([chain], {
      // Required: Name of App
      appName: 'staker.one',
    })
    //const scatter = new Scatter([chain], { appName: 'staker.one' })
    //const telos = new KeycatAuthenticator([chain], { appName: 'staker.one' })
    const wombat = new Wombat([chain], { appName: 'staker.one' })
    return (
      <div className="app">
        <UALProvider
          appName='Anchor + Authenticator Test App'
          authenticators={[anchor, wombat]}
          chains={[chain]}
          key={chain.chainId}
        >
          <Provider store={store}>
            <MainBody />
          </Provider>
        </UALProvider>
      </div>
    )
  }
}

const App = () => (
  <UALWrapper available={chains} />
)

export default App