import formActionSaga from 'redux-form-saga';
import { fork, all } from 'redux-saga/effects';
import { routinePromiseWatcherSaga } from 'redux-saga-routines';

import { walletWatches } from '../wallet/walletSaga';
import { priceWatches } from '../prices/pricesSaga';

export default function* root() {
  const allWatchers = [
    ...walletWatches,
    ...priceWatches,
    routinePromiseWatcherSaga,
    formActionSaga
  ];

  yield all(allWatchers.map(saga => fork(saga)));
}
