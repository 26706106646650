/* eslint-disable react/destructuring-assignment */
/* eslint-disable prefer-arrow-callback */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withUAL } from 'ual-reactjs-renderer'
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';

import { Button, Image, Popup } from 'semantic-ui-react';


import * as walletActions from '../wallet/walletActions';

class LoginAction extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  constructor(props) {
    super(props);
    const { cookies } = props;
    this.state = { theme: cookies.get('theme') };
  }

  componentWillMount() {
    if (this.state.theme === 'dark') {
      document.documentElement.setAttribute("data-theme", 'dark');
    }
  }

  componentDidUpdate(prevProps) {
    // If a new user record was loaded
    const { ual: { activeUser }} = this.props
    if (!prevProps.ual.activeUser && activeUser) {
      this.walletLogin();
      //console.log(activeUser)
    }
  }

  handleThemeChange(theme) {
    const { cookies } = this.props;
    cookies.set('theme', theme, { path: '/' });
    this.setState({ theme });
    this.props.themeHandler(theme);
  }

  walletLogin = () => {
    const { signInToWallet, ual: { activeUser }} = this.props;
    activeUser && signInToWallet({ accountName: this.props.ual.activeUser.accountName });
  }

  walletLogout = () => {
    const { signOutOfWallet } = this.props;
    signOutOfWallet();
  }

  renderLoginButton = () => (
    <Button
      className="st-button st-button--login"
      onClick={this.props.ual.showModal}>
      Connect Wallet
    </Button>
  )

  renderLogoutButton = () => (
    <Button
      className="st-button st-button--login"
      onClick={this.props.ual.logout}>
      {this.props.ual.activeUser.accountName} | Connected
      <button
        type="button"
        className="st-logout"
      >
        <Popup
          content="logout"
          position="bottom right"
          trigger={
            <Image src={this.state.theme === 'dark' ? process.env.PUBLIC_URL + "/assets/images/logout-icon-dark.svg" : process.env.PUBLIC_URL + "/assets/images/logout-icon.svg"} alt="logout" />
          }
        />
      </button>
    </Button>
  )

  render() {
    const { ual: { activeUser } } = this.props;
    const { theme } = this.state;

    const themeToggle = (
      <div className="st-login-wrap--theme-switch">
        <button
          basic
          circular
          icon
          className="st-button st-button--theme-switcher"
          type="button"
          onClick={() => {
            this.handleThemeChange(theme === 'dark' ? 'light' : 'dark')
          }}
        >
          <Image src={theme === 'dark' ? '../assets/theme/dark-on.svg' : '../assets/theme/dark-off.svg'} width="22px" />
        </button>
      </div>
    )


    return (
      <div>
        <div className="st-login-wrap">
          {(activeUser) ? this.renderLogoutButton() : this.renderLoginButton()}
          {themeToggle}
        </div>
      </div>
    )
  }
}

export default withUAL(withCookies(withRouter(connect(
  () => {},
  dispatch => ({
    signInToWallet: (args) => {
      dispatch(walletActions.signInToWalletRoutine(args));
    },
    signOutOfWallet: (args) => {
      dispatch(walletActions.signOutOfWalletRoutine(args));
    },
    getAllBalances: (args) => {
      dispatch(walletActions.getAccountBalancesRoutine(args));
    },
  })
)(LoginAction))));