/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withUAL } from 'ual-reactjs-renderer'
import { showSuccessAlert, showErrorAlert } from "../../../../alerts/showAlert";
import * as actions from "../../../../wallet/ualActions"

class UnLend extends Component {
  constructor() {
    super();
    this.state = {
      rexSellAmount: ''
    };
  }

  onChangeInput = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  }

  changeInputPercent = (value) => {
    this.setState({
      rexSellAmount: value,
    });
  }


  sellRex = async () => {
    const { RexPrice, ual: { activeUser } } = this.props
    const { accountName } = activeUser;
    const { rexSellAmount } = this.state;
    const data = [
      {
        contractName: 'eosio',
        contractAction: 'sellrex',
        fields: [{
          from: accountName,
          rex: RexPrice ? parseFloat(parseFloat(rexSellAmount) / parseFloat(RexPrice.price)).toFixed(4) + ' REX' : '0.0000 REX'
        }]
      },
      {
        contractName: 'eosio',
        contractAction: 'withdraw',
        fields: [{
          owner: accountName,
          amount: parseFloat(rexSellAmount).toFixed(4)+ ' TLOS'
        }]
      }
    ];

    try {
      const contractName = 'eosio';
      const contractAction = 'buyram';
      const { requestPermission } = activeUser;
      let { permission } = activeUser;
      if (!requestPermission && activeUser.scatter) {
        permission = activeUser.scatter.identity.accounts[0].authority
      } else if (requestPermission) {
        permission = requestPermission
      }
      const ramAction = actions.getMultiActions({actor: accountName, permission, contractName, contractAction, data});
      const result = await activeUser.signTransaction(ramAction, { expireSeconds: 120, blocksBehind: 3 })
      console.info('SUCCESS:', result)
      // Show success alert with transaction link
      showSuccessAlert(result.transactionId);
    } catch (e) {
      showErrorAlert(e)
      console.error('ERROR:', e)
    }
  }

  render() {
    const { RexPrice, rexMatured, ual: { activeUser }, rexBal } = this.props
    const accountName = activeUser ? activeUser.accountName : null;
    const { rexSellAmount } = this.state;
    const rexDefault = '0.0000';

    return (
      <div className="st-pannel-inner st-card__lend">
        <div className="row d-flex justify-content-center mt-5">
          <div className="col col-md-12 text-center pt-2 pb-2 border">
            <p className="st-card__bold">MATURED TLOS: {RexPrice ? parseFloat(parseFloat(RexPrice.price).toFixed(8) * parseFloat(rexMatured / 10000).toFixed(4)).toFixed(4) : '0.0000'}</p>
            <span style={{display: 'grid'}}>
              <small className="st-card__bold">Current REX balance: {rexBal}</small>
            </span>
          </div>
          <div className="container mt-3">
            <div className="col col-md-12 text-center mt-5 mb-3">
              <input
                type="number"
                min="0"
                className="st-input st-input--number"
                id="rexSellAmount"
                placeholder={rexDefault}
                value={parseFloat(rexSellAmount).toFixed(4)}
                onChange={this.onChangeInput}
                autoComplete="off"
              />
              <p className="text-left">TLOS TO RETURN</p>
              <div className="row  mt-3">
                <div className="col col-md-3 col-3 text-center">
                  <button
                    type="button"
                    className="st-button st-button--gray"
                    id="100"
                    disabled={!accountName}
                    onClick={() => {
                      this.changeInputPercent((25 / 100) * parseFloat(parseFloat(RexPrice?.price) * parseFloat(rexBal)))
                    }}> 25%
                  </button>
                </div>
                <div className="col col-md-3 col-3 text-center">
                  <button
                    type="button"
                    className="st-button st-button--gray"
                    disabled={!accountName}
                    onClick={() => {
                      this.changeInputPercent((50 / 100) * parseFloat(parseFloat(RexPrice?.price) * parseFloat(rexBal)))
                    }}> 50%
                  </button>
                </div>
                <div className="col col-md-3 col-3 text-center">
                  <button
                    type="button"
                    className="st-button st-button--gray"
                    disabled={!accountName}
                    onClick={() => {
                      this.changeInputPercent((75 / 100) * parseFloat(parseFloat(RexPrice?.price) * parseFloat(rexBal)))
                    }}> 75%
                  </button>
                </div>
                <div className="col col-md-3 col-3 text-center">
                  <button
                    type="button"
                    className="st-button st-button--gray"
                    disabled={!accountName}
                    onClick={() => {
                      this.changeInputPercent((100 / 100) * parseFloat(parseFloat(RexPrice?.price) * parseFloat(rexBal)))
                    }}> ALL
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button
          type="button"
          className="st-button st-button--purple"
          disabled={!accountName}
          onClick={() => {
            this.sellRex();
          }}> Unstake TLOS
        </button>

        {/*rexMaturities*/}
      </div>
    );
  }
}

export default withUAL(withRouter(connect(
  (state) => {
    const eosBal = state.data.getIn(['accountInfo', 'accountRows', 'core_liquid_balance'], 0);
    const rexBal = state.data.getIn(['accountInfo', 'rexRows', 'rows', 0, 'rex_balance'], 0);
    const rexFund = state.data.getIn(['accountInfo', 'rexRows', 'rexFunds', 'rows', 0, 'balance'], 0);
    const rexPrice = state.prices.getIn(['prices', 'data', 'rex', 'data', 'price'], 0);
    const rexMatured = state.data.getIn(['accountInfo', 'rexRows', 'rows', 0, 'matured_rex'], 0);

    const eosCpu = state.data.getIn(['accountInfo', 'accountRows', 'total_resources', 'cpu_weight'], 0);
    const eosNet = state.data.getIn(['accountInfo', 'accountRows', 'total_resources', 'net_weight'], 0);

    return {
      eosBal,
      rexBal,
      rexFund,
      rexPrice,
      eosCpu,
      eosNet,
      rexMatured
    };
  },
)(UnLend)));