/* eslint-disable */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withUAL } from "ual-reactjs-renderer";
import { List } from "immutable";
import { Tab, Menu, Message, Header, Divider } from "semantic-ui-react";
import * as walletActions from "../../../wallet/walletActions";
import UnLendContent from "./content/unlend";
//import Savings from './content/savings';
import { showSuccessAlert, showErrorAlert } from "../../../alerts/showAlert";
import * as actions from "../../../wallet/ualActions";

class EosRex extends Component {
  state = {
    activeIndex: 0,
    rexBuyAmount: "",
  };

  onChangeInput = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  changeInputPercent = (value) => {
    this.setState({
      rexBuyAmount: parseFloat(value).toFixed(4),
    });
  };

  btnTabChange = (e) => this.setState({ activeIndex: e.target.value });

  handleTabChange = (e, { activeIndex }) => this.setState({ activeIndex });

  buyRex = async () => {
    const {
      ual: { activeUser },
    } = this.props;
    const { accountName } = activeUser;
    const { rexBuyAmount } = this.state;
    const data = [
      {
        contractName: "eosio",
        contractAction: "deposit",
        fields: [
          {
            owner: accountName,
            amount: parseFloat(rexBuyAmount).toFixed(4) + " TLOS",
          },
        ],
      },
      {
        contractName: "eosio",
        contractAction: "buyrex",
        fields: [
          {
            from: accountName,
            amount: parseFloat(rexBuyAmount).toFixed(4) + " TLOS",
          },
        ],
      },
    ];
    {
      /*
      const proxy = {
        contractName: 'eosio',
        contractAction: 'voteproducer',
        fields: [{
          voter: accountName,
          proxy: process.env.REACT_APP_PROXY,
          producers: []
        }]
      }

      if (!didVote) {
        data.unshift(proxy);
      }
      */
    }

    try {
      const contractName = "eosio";
      const contractAction = "buyram";
      const { requestPermission } = activeUser;
      let { permission } = activeUser;
      if (!requestPermission && activeUser.scatter) {
        permission = activeUser.scatter.identity.accounts[0].authority;
      } else if (requestPermission) {
        permission = requestPermission;
      }
      const ramAction = actions.getMultiActions({
        actor: accountName,
        permission,
        contractName,
        contractAction,
        data,
      });
      const result = await activeUser.signTransaction(ramAction, {
        expireSeconds: 120,
        blocksBehind: 3,
      });
      console.info("SUCCESS:", result);
      // Show success alert with transaction link
      showSuccessAlert(result.transactionId);
    } catch (e) {
      showErrorAlert(e);
      console.error("ERROR:", e);
    }
  };

  render() {
    const { activeIndex, rexBuyAmount } = this.state;
    const {
      eosBal,
      rexBal,
      RexPrice,
      TokenPrices,
      rexApr,
      ual: { activeUser },
    } = this.props;
    const acctName = activeUser ? this.props.ual.activeUser.accountName : null;
    const rexDefault = "0.0000";

    const panes = [
      {
        menuItem: (
          <Menu.Item key="fund" className="fund">
            <span>Stake (REX)</span>
          </Menu.Item>
        ),
        render: () => <Tab.Pane attached={false}>{fund}</Tab.Pane>,
      },
      {
        menuItem: (
          <Menu.Item key="unlend" className="unlend">
            <span>Unstake (REX)</span>
          </Menu.Item>
        ),
        render: () => (
          <Tab.Pane attached={false}>
            <UnLendContent RexPrice={RexPrice} />
          </Tab.Pane>
        ),
      },
      {
        /*
        menuItem: (
          <Menu.Item key="savings" className="savings">
            <span>Savings</span>
          </Menu.Item>
        ),
        render: () => <Tab.Pane attached={false}><Savings RexPrice={RexPrice} /></Tab.Pane>
        */
      },
    ];

    const fund = (
      <div className="st-pannel-inner st-card__lend">
        <div className="row d-flex justify-content-center mt-5">
          <div className="col col-md-12 text-center pt-2 pb-2 border">
            <p className="st-card__bold">LIQUID TLOS: {parseFloat(eosBal)}</p>
          </div>
          <div className="container mt-3">
            <div className="col col-md-12 text-center mt-5 mb-3">
              <input
                className="st-input st-input--number"
                type="number"
                id="rexBuyAmount"
                placeholder={rexDefault}
                value={rexBuyAmount}
                onChange={this.onChangeInput}
                autoComplete="off"
              />
              <p className="text-left">TLOS TO DEPOSIT</p>
              <div className="row  mt-3">
                <div className="col col-md-3 col-3 text-center">
                  <button
                    type="button"
                    className="st-button st-button--gray"
                    id="100"
                    disabled={!acctName}
                    onClick={() => {
                      this.changeInputPercent((25 / 100) * parseFloat(eosBal));
                    }}
                  >
                    {" "}
                    25%
                  </button>
                </div>
                <div className="col col-md-3 col-3 text-center">
                  <button
                    type="button"
                    className="st-button st-button--gray"
                    disabled={!acctName}
                    onClick={() => {
                      this.changeInputPercent((50 / 100) * parseFloat(eosBal));
                    }}
                  >
                    {" "}
                    50%
                  </button>
                </div>
                <div className="col col-md-3 col-3 text-center">
                  <button
                    type="button"
                    className="st-button st-button--gray"
                    disabled={!acctName}
                    onClick={() => {
                      this.changeInputPercent((75 / 100) * parseFloat(eosBal));
                    }}
                  >
                    {" "}
                    75%
                  </button>
                </div>
                <div className="col col-md-3 col-3 text-center">
                  <button
                    type="button"
                    className="st-button st-button--gray"
                    disabled={!acctName}
                    onClick={() => {
                      this.changeInputPercent((100 / 100) * parseFloat(eosBal));
                    }}
                  >
                    {" "}
                    ALL
                  </button>
                </div>
              </div>
            </div>
            <div className="col col-md-12 mt-4 mb-4">
              <p
                className="st-card__bold p-1 uppercase"
                hidden={!rexBuyAmount}
                style={{ fontSize: "12px" }}
              >
                Investment Amount:{" "}
                <span style={{ color: "var(--purple)" }}>{rexBuyAmount}</span>
              </p>
              <p
                className="st-card__bold p-1 uppercase"
                hidden={!rexBuyAmount}
                style={{ fontSize: "12px" }}
              >
                Estimated Return (1y):{" "}
                <span style={{ color: "var(--purple)" }}>
                  {((rexApr / 100) * parseFloat(rexBuyAmount)).toFixed(4)}
                </span>
              </p>
            </div>
          </div>
          {/*
          <div className="col col-md-12 text-center mt-3">
            <p className="st-card__bold pb-3 uppercase" hidden={!rexBuyAmount} style={{ fontSize: '14px' }}>Estimated Amount: <span style={{ color: 'var(--purple)' }}>{RexPrice ? parseFloat(rexBuyAmount / parseFloat(RexPrice.price).toFixed(8)).toFixed(4) : '0.0000'} {rexBuyAmount && ' REX'}</span></p>
          </div>
          */}
        </div>
        <button
          className="st-button st-button--purple"
          type="button"
          size="huge"
          disabled={!acctName}
          onClick={() => {
            this.buyRex();
          }}
        >
          {" "}
          Stake TLOS
        </button>
      </div>
    );

    // Sidebar
    const sidebar = (
      <div className="st-card-rex__left">
        <div className="st-card__rex-menu">
          <h4 className="st-resources__title st-card__info">
            <span>
              TLOS <small>Price</small>
            </span>
          </h4>
          <p className="st-card__bold">
            ${TokenPrices ? TokenPrices.price : "0.00"}
          </p>
        </div>
        <div className="st-card__rex-menu">
          <h4 className="st-resources__title st-card__info">
            <span>
              <small>Total TLOS Staked</small>
            </span>
          </h4>
          <p className="st-card__bold gray">
            {RexPrice
              ? `${parseFloat(
                  parseFloat(0.00016681971).toFixed(8) *
                    parseFloat(rexBal).toFixed(4)
                ).toFixed(4)} TLOS`
              : "0.0000 TLOS"}
            <small className="st-card__bold float-right">
              $
              {RexPrice
                ? parseFloat(
                    parseFloat(0.00016681971).toFixed(8) *
                      parseFloat(rexBal).toFixed(4) *
                      parseFloat(TokenPrices && TokenPrices.price).toFixed(4)
                  ).toFixed(2)
                : "0.0000 TLOS"}
            </small>
          </p>
        </div>
        <div className="st-card__rex-menu">
          <h4 className="st-resources__title st-card__info">
            <span>
              Estimated <small>Return</small>
            </span>
          </h4>
          <p className="st-card__bold gray">
            {RexPrice && rexBal
              ? `${(
                  (rexApr / 100) *
                  parseFloat(
                    parseFloat(RexPrice.price).toFixed(8) *
                      parseFloat(rexBal).toFixed(4)
                  )
                ).toFixed(4)} TLOS`
              : "0.0000 TLOS"}
          </p>
        </div>
        <div className="st-card__rex-menu">
          <h4 className="st-resources__title st-card__info">
            <span>
              Current <small>Interest</small>
            </span>
          </h4>
          <p className="st-card__bold gray">{rexApr}% APR</p>
        </div>
      </div>
    );

    const headerContent = () => {
      return (
        <div className="col-12 justify-content-center">
          <Header as="h1">
            <Header.Content style={{ color: "#fff", fontWeight: "bolder" }}>
              <Header.Subheader style={{ color: "rgb(208, 208, 208)" }}>
                {/*Staker.one makes it easier to start earning passive income with Telos (<b>$TLOS</b>) staking rewards (via REX)*/}
                <Divider />
                <ul style={{ color: "#ffd600", fontWeight: "bolder" }}>
                  <li>
                    You must wait at least 4 days after staking to unstake your
                    TLOS.
                  </li>
                  <li>
                    Unstake when you are ready, you will receive your original
                    TLOS amount + interest earned.
                  </li>
                </ul>
              </Header.Subheader>
            </Header.Content>
          </Header>
        </div>
      );
    };

    return (
      <div>
        <div className="st-top-header">
          <Message content={headerContent} className="header-message" />
        </div>
        <div className="st-main-body-inner">
          <div className="st-main-body-rex">
            <div className="st-card st-card-rex">
              {sidebar}
              <div className="st-card-rex__right">
                <Tab
                  menu={{
                    secondary: true,
                    pointing: true,
                    widths: 3,
                    size: "massive",
                  }}
                  panes={panes}
                  activeIndex={activeIndex}
                  onTabChange={this.handleTabChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withUAL(
  withRouter(
    connect(
      (state) => {
        const acctName = state.data.getIn(["identity", "account_name"]);
        const eosBal = state.data.getIn(
          ["accountInfo", "accountRows", "core_liquid_balance"],
          0
        );
        const rexBal = state.data.getIn(
          ["accountInfo", "rexRows", "rows", 0, "rex_balance"],
          0
        );
        const rexFund = state.data.getIn(
          ["accountInfo", "rexRows", "rexFunds", "rows", 0, "balance"],
          0
        );
        const cpuLoans = state.data.getIn(
          ["accountInfo", "eosRows", "cpuloans", "rows"],
          List()
        );
        const netLoans = state.data.getIn(
          ["accountInfo", "eosRows", "netloans", "rows"],
          List()
        );

        const rexApr = state.data.getIn(["restApi", "data", "apr"], 0);
        console.log(rexApr)

        const voteBp = state.data.getIn(
          ["accountInfo", "accountRows", "voter_info", "producers"],
          List()
        );
        const accVoteProxy = state.data.getIn(
          ["accountInfo", "accountRows", "voter_info", "proxy"],
          ""
        );
        const didVote =
          voteBp.toArray().length >= 21 ? true : accVoteProxy ? true : false;

        const eosCpu = state.data.getIn(
          [
            "accountInfo",
            "accountRows",
            "self_delegated_bandwidth",
            "cpu_weight",
          ],
          0
        );
        const eosNet = state.data.getIn(
          [
            "accountInfo",
            "accountRows",
            "self_delegated_bandwidth",
            "net_weight",
          ],
          0
        );
        const eosCpuUsed = state.data.getIn(
          ["accountInfo", "accountRows", "cpu_limit", "used"],
          0
        );
        const eosCpuMax = state.data.getIn(
          ["accountInfo", "accountRows", "cpu_limit", "max"],
          0
        );
        const eosNetUsed = state.data.getIn(
          ["accountInfo", "accountRows", "net_limit", "used"],
          0
        );
        const eosNetMax = state.data.getIn(
          ["accountInfo", "accountRows", "net_limit", "max"],
          0
        );
        const eosRamUsed = state.data.getIn(
          ["accountInfo", "accountRows", "ram_usage"],
          0
        );
        const eosRamMax = state.data.getIn(
          ["accountInfo", "accountRows", "ram_quota"],
          0
        );
        const stakedAccounts = state.data.getIn(
          ["accountInfo", "eosStakedRows", "rows"],
          List()
        );

        const eosCpuTotal = parseFloat((eosCpuUsed / eosCpuMax) * 100).toFixed(
          2
        );
        const eosNetTotal = parseFloat((eosNetUsed / eosNetMax) * 100).toFixed(
          2
        );
        const eosRamTotal = Math.round((eosRamUsed / eosRamMax) * 100).toFixed(
          2
        );

        //console.log(rexBal)

        return {
          acctName,
          eosBal,
          rexBal,
          rexFund,
          eosCpu,
          eosNet,
          eosCpuUsed,
          eosCpuMax,
          eosCpuTotal,
          eosNetUsed,
          eosNetMax,
          eosNetTotal,
          eosRamUsed,
          eosRamMax,
          eosRamTotal,
          stakedAccounts,
          cpuLoans,
          netLoans,
          didVote,
          accVoteProxy,
          rexApr,
        };
      },
      (dispatch) => ({
        voteProxy: (id) => dispatch(walletActions.voteProxyRoutine({ id })),
      })
    )(EosRex)
  )
);
