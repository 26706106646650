/* eslint-disable */
export const getActionData = (payload) => {
  //console.log(payload)
  let data;
  switch(payload.type) {
    case ('stake'):
      data = {
        from: payload.actor,
        receiver: payload.accountName ? payload.accountName : payload.actor,
        stake_net_quantity: payload.netAmount ? parseFloat(payload.netAmount).toFixed(4) + ' TLOS' : '0.0000 TLOS',
        stake_cpu_quantity: payload.cpuAmount ? parseFloat(payload.cpuAmount).toFixed(4) + ' TLOS' : '0.0000 TLOS',
        transfer: false
      }
      break;
    case ('unstake'):
      data = {
        from: payload.actor,
        receiver: payload.accountName ? payload.accountName : payload.actor,
        unstake_net_quantity: payload.netAmount ? parseFloat(payload.netAmount).toFixed(4) + ' TLOS' : '0.0000 TLOS',
        unstake_cpu_quantity: payload.cpuAmount ? parseFloat(payload.cpuAmount).toFixed(4) + ' TLOS' : '0.0000 TLOS',
      }
      break;
    case ('buyRam'):
      data = {
        payer: payload.actor,
        receiver: payload.accountName ? payload.accountName : payload.actor,
        quant: payload.quant ? parseFloat(payload.quant).toFixed(4) + ' TLOS' : '0.0000 TLOS',
      }
      break;
    case ('sellRam'):
      data = {
        account: payload.actor,
        bytes: payload.bytes ? parseFloat(payload.bytes) : 0,
      }
      break;
    case ('refund'):
      data = {
        owner: payload.actor,
      }
      break;
    default:
      break;
  }
  return data
}

export const getActions = (payload) => {
  //console.log(payload)
  const data = getActionData(payload)
  return {
    actions: [
      {
        account: payload.contractName,
        name: payload.contractAction,
        authorization: [{ actor: payload.actor, permission: payload.permission }],
        data,
      }
    ],
  }
}


export const getMultiActionData = (payload) => {
  //console.log(payload)
  let data;
  switch(payload.type) {
    case ('buyRam'):
      data = {
        payer: payload.actor,
        receiver: payload.receiver ? payload.receiver : payload.actor,
        quant: payload.quant ? parseFloat(payload.quant).toFixed(4) + ' TLOS' : '0.0000 TLOS',
      }
      break;
    default:
      break;
  }
  return data
}

export const getMultiActions = (payload) => {
  //console.log(payload)
  const sortActions = [];

  payload.data.forEach(result => [
    sortActions.push({
      account: result.contractName,
      name: result.contractAction,
      authorization: [{actor: payload.actor, permission: payload.permission}],
      data: result.fields[0],
    })
  ]);

  //console.log(sortActions)
  return {
    actions: sortActions,
  }
}