/* eslint-disable */
// Main Header
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { Grid, Button, Header, Message } from "semantic-ui-react";
//import Rotator from './rotator';
import * as walletActions from "../wallet/walletActions";

class HeaderMessage extends Component {
  state = { visible: true };

  handleDismiss = () => {
    this.setState({ visible: false });
  };

  routeChange = (url) => {
    this.props.history.push(url);
  };

  render() {
    const hideOnPages = [
      "/account",
      "/account/",
      "/rewards",
      "/rewards/",
      "/rex",
      "/rex/",
    ];

    const mainContent = () => {
      return (
        <div>
          <Grid doubling columns={2} className="st-card">
            <Grid.Column floated="left" width={10}>
              <Header as="h2">
                <Header.Content>
                  Telos Staking Rewards
                  <Header.Subheader>
                    Start Earning Passive Income With $TLOS Staking Rewards.
                    (via REX)
                  </Header.Subheader>
                </Header.Content>
              </Header>
            </Grid.Column>
            <Grid.Column floated="right" width={6}>
              <Button
                as="a"
                content="Start Earning"
                fluid
                circular
                className="st-button st-button--blue-solid st-button--large"
                onClick={() => {
                  return this.routeChange("/rewards");
                }}
              />
            </Grid.Column>
          </Grid>
        </div>
      );
    };
    return (
      <div>
        {/*<Rotator status={this.props.history.location.pathname == "/swap" || this.props.history.location.pathname == "/account"} /> */}
        {this.props.history.location.pathname != "/swap" && (
          <div
            className="st-top-header"
            hidden={
              hideOnPages.indexOf(this.props.history.location.pathname) !== -1
            }
          >
            <Message
              hidden={!this.state.visible}
              content={mainContent}
              className="header-message"
            />
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(
  connect(
    (state) => {
      const acctName = state.data.getIn(["identity", "account_name"]);
      return {
        acctName,
      };
    },
    (dispatch) => ({
      signInToWallet: (args) => {
        dispatch(walletActions.signInToWalletRoutine(args));
      },
      sendDonation: (args) => {
        dispatch(walletActions.transferEosRoutine(args));
      },
    })
  )(HeaderMessage)
);
